<template>
  <a-modal
    v-model="messageCreateVisiable"
    :centered="true"
    :keyboard="false"
    :footer="null"
    :width="800"
    title="公告详情"
  >
    <a-layout v-if="messageData" class="message-info">
      <a-layout-content class="message-content-one">
        <p v-if="messageData.creator">
          <a-icon type="user" />创建人：{{ messageData.creator.username }}
        </p>
        <p>
          <a-icon type="clock-circle" />待发送时间：{{ messageData.sendTime }}
        </p>
      </a-layout-content>
      <a-layout-content class="message-content-two">
        <p>
          <a-icon type="smile" />发送状态：{{
            messageData.marked ? "已发送" : "待发送"
          }}
        </p>
        <p>
          <a-icon type="clock-circle" />创建时间：{{ messageData.createTime }}
        </p>
      </a-layout-content>
    </a-layout>
    <a-layout v-if="messageData" class="message-info">
      <a-layout-content class="message-content-three">
        <span><a-icon type="message" />公告内容：</span><br />
        <div v-html="messageData.content" class="info"></div>
      </a-layout-content>
    </a-layout>

    <a-layout
      v-if="messageData && attachmentList && attachmentList.length"
      class="message-info"
    >
      <a-layout-content class="message-content-three">
        <span><a-icon type="message" />附件：</span><br />
        <div class="info" v-for="(item, index) of attachmentList" :key="index">
          <a target="_blank" :href="item.url">{{ item.filename }}</a>
        </div>
      </a-layout-content>
    </a-layout>

    <a-layout v-if="entities" class="message-info">
      <a-layout-content class="message-content-four">
        <div><a-icon type="crown" />详情：</div>
        <div style="margin-top: 10px">
          <a-tag
            style="margin: 0 5px 5px"
            v-for="(item, index) of userList"
            :key="index"
          >
            {{ item.username }}
          </a-tag>
        </div>
      </a-layout-content>
    </a-layout>
  </a-modal>
</template>

<script>
export default {
  name: "FenxiangDetail",
  data() {
    return {
      messageCreateVisiable: false,
      loading: true,
      messageData: null,
      entities: null,
    };
  },
  methods: {
    setData(data) {
      this.messageCreateVisiable = true;
      this.messageData = data;
      this.loadRuleData(data.rules);
    },
    loadRuleData(rules) {
      this.loading = true;
      this.$get(`group/convert-rules/${rules}`).then((resp) => {
        this.loading = false;
        this.entities = resp.data.entities;
      });
    },
  },
  computed: {
    attachmentList() {
      let arr = JSON.parse(this.messageData.attachment || "[]");
      return arr;
    },
    userList() {
      let map = {};
      if (this.entities) {
        for (let item of this.entities) {
          let list = item.userList;
          let operation = item.operation;

          for (let u of list) {
            if (operation == "+") map[u.userId] = u;
            else delete map[u.userId];
          }
        }
      }
      return Object.values(map);
    },
  },
};
</script>
<style lang="less" scoped>
@import "../MessageDetail";
</style>
